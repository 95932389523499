<template>
  <section
    id="home-solicitar-atendimento-personalizado"
    class="pad-lg"
    style="padding-bottom: 110px"
  >
    <div class="container">
      <p class="center text-high" data-aos="fade-up">
        <span class="text-lg"
          >Selecione os <b>módulos</b> abaixo e solicite um<b>
            atendimento personalizado com nosso especialista.</b
          ></span
        >
      </p>
      <div class="modulos-content-accordion pad-top-md">
        <!-- PAINEL CLÍNICAS E CONSULTÓRIOS -->
        <button
          type="button"
          id="btn-collapse-clinicas"
          class="btn-collapse collapse-clinicas"
          data-toggle="modal"
          data-target="#formSolicitarAtendimento"
          data-aos="fade-up"
          @click="itens = ['SPDATA Minha Clínica']"
        >
          SPDATA Minha Clínica
        </button>

        <!-- PAINEL HOSPITAIS -->
        <button
          type="button"
          id="btn-collapse-hospitais"
          class="btn-collapse collapse-hospitais"
          data-toggle="modal"
          data-target="#formSolicitarAtendimento"
          data-aos="fade-up"
          @click="itens = []"
        >
          SPDATA Gestão Hospitalar
        </button>

        <!-- PAINEL OPERADORAS DE PLANOS -->
        <button
          type="button"
          id="btn-collapse-operadoras"
          class="btn-collapse collapse-operadoras"
          data-toggle="modal"
          data-target="#formSolicitarAtendimento"
          data-aos="fade-up"
          @click="itens = ['SPDATA Gestão de Operadoras']"
        >
          SPDATA Gestão de Operadoras
        </button>

        <!-- MODAL -->
        <div id="formSolicitarAtendimento" class="modal fade" role="dialog">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body">
            <a class="close" href="#" data-dismiss="modal">
              <span class="close" aria-hidden="true">x</span>
            </a>
                <div class="row group">
                  <div class="col col-2-4">
                    <h5 class="titulo">Solicitar Atendimento Personalizado</h5>
                  </div>
                </div>
                <form
                  :class="this.errors ? 'errors' : false"
                  class="formulario"
                  @submit.prevent="saveAtendimento()"
                >
                  <div class="row group">
                    <div class="col col-2-4">
                      <ul>
                        <li>
                          <input
                            type="text"
                            placeholder="Nome Completo *"
                            v-model="nome"
                            required
                            @invalid="invalidateForm"
                          />
                          <span v-show="validations.nome" class="error-message"
                            >Este campo é obrigatório.</span
                          >
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="E-mail *"
                            v-model="email"
                            required
                            @invalid="invalidateForm"
                            pattern="^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$"
                          />
                          <span v-show="validations.email" class="error-message"
                            >Este campo é obrigatório.</span
                          >
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Telefone *"
                            v-model="telefone"
                            required
                            @invalid="invalidateForm"
                            maxlength="15"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                          />
                          <span
                            v-show="validations.telefone"
                            class="error-message"
                            >Este campo é obrigatório.</span
                          >
                        </li>
                        <li>
                          <textarea
                            placeholder="Mensagem *"
                            v-model="mensagem"
                            required
                            @invalid="invalidateForm"
                          />
                          <span
                            v-show="validations.mensagem"
                            class="error-message"
                            >Este campo é obrigatório.</span
                          >
                        </li>
                      </ul>
                    </div>
                    <div class="col col-2-4">
                      <div class="itens-selecionados" v-if="itens == 'SPDATA Minha Clínica' || itens == 'SPDATA Gestão de Operadoras'">
                        <h5>Itens Selecionados</h5>
                        <div class="itens-selecionados-tags">
                          <span v-for="item in itens" :key="item">{{
                            item
                          }}</span>
                        </div>
                      </div>
                      <div class="itens-selecionados" v-else>
                        <h5>Selecione o módulo desejado</h5>
                        <div class="column">
                          <div class="col col-1-1" @click="addItem('Assistencial')">
                            <div :class="[itens.indexOf('Assistencial') > -1 ? 'label-item-selected' : 'label-item']">
                              <span>Assistencial</span>
                            </div>
                          </div>
                          <div class="col col-1-1" @click="addItem('Administrativo')">
                            <div :class="[itens.indexOf('Administrativo') > -1 ? 'label-item-selected' : 'label-item']">
                              <span>Administrativo</span>
                            </div>
                          </div>
                          <div class="col col-1-1" @click="addItem('Apoio')">
                            <div :class="[itens.indexOf('Apoio') > -1 ? 'label-item-selected' : 'label-item']">
                              <span>Apoio</span>
                            </div>
                          </div>
                          <div class="col col-1-1" @click="addItem('Suprimentos')">
                            <div :class="[itens.indexOf('Suprimentos') > -1 ? 'label-item-selected' : 'label-item']">
                              <span>Suprimentos</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-1-1">
                      <button class="btn btn-sm btn-pri" type="submit">
                        Enviar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import SetupApplicationService from "../services/SetupApplicationService.js";

export default {
  data() {
    return {
      itens: [],
      nome: null,
      email: null,
      telefone: null,
      mensagem: null,
      validations: {
        nome: false,
        email: false,
        telefone: false,
        mensagem: false,
      },
      setupApplicationService: new SetupApplicationService(),
      errors: false,
    };
  },
  methods: {
    displaySuccessTooltip(message, type) {
      this.$notify({
        group: 'main',
        text: message,
        type: type
      });
    },

    invalidateForm() {
      this.errors = true;
    },

    addItem(value) {
      if (this.itens.length > 0) {
        this.itens.splice(0, 1);
        this.itens.push(value);
      } else {
        this.itens.push(value);
      }
    },

    saveAtendimento(e) {
      const payload = {
        nome: this.nome,
        email: this.email,
        telefone: this.telefone,
        itens: this.itens,
        mensagem: this.mensagem,
      };

      if (this.checkForm()) {
        this.setupApplicationService.setAtendimento(payload);
        this.nome = '';
        this.email = '';
        this.telefone = '';
        this.mensagem = '';
        this.displaySuccessTooltip('Atendimento solicitado com sucesso!', 'success');
      }
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    checkForm() {
      if (
        this.nome &&
        this.validEmail(this.email) &&
        this.telefone &&
        this.mensagem
      ) {
        return true;
      }

      this.validations = {};

      this.validations.nome = !this.nome;
      this.validations.telefone = !this.telefone;
      this.validations.mensagem = !this.mensagem;
      this.validations.email = !this.validEmail(this.email);
    },
  },
};
</script>
